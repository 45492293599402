<template>
  <div class="loufengLocation">
    <div class="header">
      <van-icon class="back" name="arrow-left" size="25" color="white" @click="$router.go(-1)" />
      <span class="title">切换城市</span>
    </div>
    <div class="main">
      <van-search
        v-model="locationValue"
        shape="round"
        background="white"
        placeholder="搜索位置"
        @blur="blur"
      />
      <div class="currentCity">
        <van-icon name="location-o"></van-icon>
        <p>当前城市</p>
        <span style="color: rgb(242,163,47)">{{ currentLocation }}</span>
      </div>
      <div class="hot-city">
        <div class="hot-city-title">
          <van-icon name="fire" color="red"></van-icon>
          <span>热门城市</span>
        </div>
        <div class="hot-city-list">
          <div class="city" v-for="(item, index) in hotestCity" :key="index" @click="toLouFeng(item.city)">{{ item.city }}</div>
        </div>
      </div>
      <!--    遮挡层
      下面城市列表中的 letter 层，会在苹果手机浏览器里面漏出一点点内容，
      需要这div进行一个遮挡
      -->
      <div class="occlude"></div>
      <!-- 城市列表 -->
      <div class="city-list-box">
        <div class="city-list" v-for="(item, index) in provinces" :key="index">
            <div class="letter" :id="item.letter">{{ item.letter }}</div>
          <ul>
            <li class="city" :class="province.city" v-for="(province, index) in item.provinces" :key="index" @click="toLouFeng(province.city)">{{ province.province + province.city }}</li>
          </ul>
        </div>
      </div>
      <!-- 城市字母列表 -->
      <div class="letter-box">
        <p v-for="(letter, index) in letters" :key="index" @click="toLetterLocation(letter)"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd">{{ letter }}</p>
      </div>
      <div class="active-letter" v-if="isLetter">
        <span>{{ activeLetter }}</span>
      </div>
    </div>
    <van-overlay :show="loading" z-index="101">
      <div class="wrapper" @click.stop>
        <van-loading type="spinner" color="#f21313">数据加载中...</van-loading>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { getCityList } from '@/api/loufeng.js';
import { pinyin } from 'pinyin-pro';

export default {
  name: 'loufengLocation',
  data() {
    return {
      locationValue: '',
      currentLocation: '未知',
      hotestCity: [],
      provinces: [], // 省份
      provincesCopy: [], // 省份副本
      loading: true, // 遮罩层加载数据
      letter: '',
      city: '',
      letters: [
        'A','B','C','D','E','F','J','H','I','J','K','L','M',
        'N','O','P','Q','R','S','T','U','V','W','X','Y','Z','#'
      ], // 字母
      letterHeight: [], // 字母的高度
      startY: '', // 字母列表距离顶部的高度
      activeLetter: 'A', // 激活时的字母
      isLetter: false, // 是否显示字母气泡
    }
  },
  created() {
    this.getLocation();
    this.getLoufengCityList();
  },
  mounted() {
    setTimeout(() => {
      this.letterHeight = this.letters.map(item => {
        let dom = document.getElementById(item) ? document.getElementById(item) : null;
        if(dom) {
          let height = document.getElementById(item).offsetTop;
          let obj = {
            letter: item,
            height: height
          };
          return obj;
        }
      });
      this.startY = document.getElementsByClassName('letter-box')[0].offsetTop;
    },1000);
  },
  methods: {
    handleTouchStart() {
      this.isLetter = true;
    },
    handleTouchMove(e) {
      const touchY = e.touches[0].clientY || e.touches[0].pageY
      let index = Math.floor((touchY - this.startY) / 15) // 15 为字母的高度，通过此计算出字母的下标值
      if(index > this.letters.length -1) {
        index = this.letters.length - 1;
      } else if(index < 0) {
        index = 0;
      }
      this.activeLetter = this.letters[index];
      this.toLetterLocation(this.activeLetter, 'noTimeNeeded');
    },
    handleTouchEnd() {
      this.isLetter = false;
    },
    // 选择当前城市
    blur() {
      if(this.locationValue) {
        let dom = document.getElementsByClassName(this.locationValue)[0];
        let domC = document.getElementsByClassName('city-list-box')[0];
        domC.scrollTop = dom.offsetTop - domC.offsetTop - 40;
      }
    },
    // 跳转字母对应的城市
    toLetterLocation(letter, param) {
      this.isLetter = true;
      if(param !== 'noTimeNeeded') {
        setTimeout(()=> {this.isLetter = false}, 1000);
      }
      this.activeLetter = letter; // 更改气泡的字母
      let letterHeight = [];
      this.letterHeight.forEach(item => {
        if(item.letter === letter) {
          letterHeight = item.height;
        }
      })
      let dom = document.getElementsByClassName('city-list-box')[0];
      dom.scrollTop = letterHeight - dom.offsetTop + 2;
    },
    // 获取当前位置信息
    getLocation() {
      this.currentLocation = localStorage.getItem('location');
      if(!this.currentLocation) {
        this.currentLocation = '未知';
      }
    },
    async getLoufengCityList() {
      let req = {
        hotestNum: 9
      }
      let res = await this.$Api(getCityList, req);
      if(res && res.code === 200) {
        this.hotestCity = res.data.hotest;
        let tempArr = this.extractFirstLetter(res.data.list);
        // 按字母对城市列表进行排序
        tempArr.sort((a,b) => {
          return a.letter.localeCompare(b.letter)
        })
        this.provincesCopy = tempArr;
        let tempObj = this.provincesSort(tempArr);
        this.provinces = this.inductiveCity(tempObj.letterArr);
        this.provinces = this.provinces.concat([{
          letter: '#',
          provinces: tempObj.noLetterArr
        }]);
        this.letters = this.provinces.map(item => item.letter);
        this.loading = false;
      } else {
        this.$toast('数据请求异常');
      }
    },
    // 将每个对象中的省份，提取首字母，并存回去
    extractFirstLetter(list) {
      let tempArr = list.map(item => {
        let first = pinyin(item.province.substring(0,1), {
          pattern: 'first',
          toneType: 'none'
        }).toUpperCase();
        item.letter = first
        return item;
      })
      return tempArr;
    },
    // 将一个数组里的对象，是字母的放在一个数据，非数组放在另一个数组
    provincesSort(provinces) {
      let obj = {};
      let letterArr = [];
      let noLetterArr = []; 
      let pattern = new RegExp("[A-Z]");
      provinces.forEach(item => {
        if(pattern.test(item.letter)) {
          letterArr.push(item);
        } else {
          noLetterArr.push(item);
        }
      });
      obj.letterArr = letterArr;
      obj.noLetterArr = noLetterArr;
      return obj;
    },
    /**
     * 业务：数组对象中，将相同的某个属性的对象归纳在一个数组中，并且将相同的属性
     * 提取出来，存放在对象中，最后将对象存在数组中；
     * @param ArrayList 
     * [
     *  {
     *    attr: ''
     *  },
     *  {
     *    attr: ''
     *   }
     * ]
     * @return ArrayList 
     * [{
     *    attr: '',
     *    obj: {
     *     attr:'', 
     *     attr2: []
     *    }
     * }]
    */
    inductiveCity(province){
      let obj={}, newArr = [];
      province.forEach(function(item){
        if(!obj[item.letter]){
          var tempObj = {
            letter: '',
            provinces: []
          };
          tempObj.letter = item.letter;
          var tempArr = [];
          tempArr.push(item);
          tempObj.provinces = tempArr;
          newArr.push(tempObj);
          obj[item.letter] = item;
        } else {
          newArr.forEach(function(value){
            if(value.provinces[0].letter == item.letter){
              value.provinces.push(item);
            }
          })
        }
      });
      return newArr;
    },
    toLouFeng(city) {
      localStorage.setItem('location', city);
      this.$router.push({
        name: 'loufeng',
        params: {
          location: city
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .loufengLocation {
    overflow: hidden;
    .header {
      height: 44px;
      width: 100%;
      background-color: rgb(242,19,19);
      color: white;
      font-size: 20px;
      text-align: center;
      line-height: 44px;
      .back {
        position: absolute;
        left: 14px;
        top: 10px;
      }
    }
    .main {
      .currentCity {
        padding: 20px 10px;
        font-size: 14px;
        p {
          display: inline-block;
          margin: 0 10px 0 3px;
        }
      }
      .hot-city {
        padding: 0 10px;
        font-size: 13px;
        .hot-city-list {
          padding: 10px 0px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .city {
            height: 25px;
            width: 100px;
            margin: 2px 5px;
            border: 1px solid rgba(141, 137, 137, 0.87);
            border-radius: 5px;
            line-height: 25px;
            text-align: center;
          }
        }
      }
      // 遮挡层
      .occlude {
        height:5px;
        width:100%;
        background-color:white;
        position: relative;
        top:1px;
      }
      // 城市盒子
      .city-list-box {
        height: calc(100vh - 280px);
        overflow: auto;
        .city-list {
          .letter {
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            padding: 0 10px;
            box-sizing: border-box;
            background-color: rgb(228, 222, 222);
            position: sticky;
            top: 0;
          }
          .city {
            padding: 2px 10px;
            font-size: 17px;
            line-height: 20px;
          }
        }
      }
      // 城市字母
      .letter-box{
        position: fixed;
        z-index: 100;
        bottom: 10px;
        right: 0;
        min-height: 350px;
        width: 40px;
        text-align: center;
        p{
          height: 15px;
          width: 40px;
          line-height: 14px;
          text-align: center;
        }
      }
      // 字母气泡
      .active-letter {
        height: 50px;
        width: 50px;
        background-color: skyblue;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        top: 350px;
        right: 60px;
        line-height: 50px;
        text-align: center;
        span {
          font-size: 24px;
          color: white;
        }
      }
    }
    // 整页加载
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: white;
    }
    /deep/ .van-loading--spinner {
      margin-top: unset;
    }
  }
</style>
